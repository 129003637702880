import React from "react";

const AdminHeader = () => {
  return (
    <header style={{ backgroundColor: "#333", color: "#fff", padding: "10px" }}>
      <h1>Admin Panel</h1>
    </header>
  );
};

export default AdminHeader;